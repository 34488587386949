import React from "react";
import Layout from "../../components/layout";
import Link from "gatsby-link";
import Button from "../../components/Button";
// import api from "../api.js";
// import { AuthContext } from "../context/GlobalContextProvider";

function Edicao() {
    // const context = useContext(AuthContext)
    const testecss = "font-bold text-center base-golden-center my-16"

  return (
    <Layout>
      <h1 className={testecss}>
        Edição
      </h1>
        <div className="grid grid-cols-3 gap-4">
            <div className="card rounded-md overflow-hidden ring-1 ring-blue-600 ring-opacity-20">
            <div className="p-6 flex flex-col justify-between ">
                <Link to="/" className="rounded-md">
                <div className="text-center flex flex-col">
                    <div className="my-8">
                    <h2 className="font-bold mb-5 base-golden-center">
                        Parceiros
                    </h2>
                    <p className="my-8">
                        {`Adicionar imagens à seção de parceiros`}
                    </p>
                    <Button className="mt-5">Editar</Button>
                    </div>
                </div>
                </Link>
            </div>
            </div>
            <div className="card rounded-md overflow-hidden ring-1 ring-blue-600 ring-opacity-20">
            <div className="p-6 flex flex-col justify-between ">
                <Link to="/" className="rounded-md overflow-hidden ring-1 ring-blue-600 ring-opacity-20">
                <div className="card text-center flex flex-col overflow-hidden">
                    <div className="my-8">
                    <h2 className="font-bold mb-5 base-golden-center">
                        Serviços
                    </h2>
                    <p className="my-8">
                        {`Adicionar, editar e deletar Serviços`}
                    </p>
                    <Button className="mt-5">Editar</Button>
                    </div>
                </div>
                </Link>
            </div>
            </div>
            <div className="card rounded-md overflow-hidden ring-1 ring-blue-600 ring-opacity-20">
            <div className="p-6 flex flex-col justify-between ">
                <Link to="/" className="rounded-md overflow-hidden ring-1 ring-blue-600 ring-opacity-20">
                <div className="card text-center flex flex-col overflow-hidden">
                    <div className="my-8">
                    <h2 className="font-bold mb-5 base-golden-center">
                        A Empresa
                    </h2>
                    <p className="my-8">
                        {`Adicionar e editar texto da seção de A Empresa`}
                    </p>
                    <Button className="mt-5">Editar</Button>
                    </div>
                </div>
                </Link>
            </div>
            </div>
            <div className="card rounded-md overflow-hidden ring-1 ring-blue-600 ring-opacity-20">
            <div className="p-6 flex flex-col justify-between ">
                <Link to="/" className="rounded-md overflow-hidden ring-1 ring-blue-600 ring-opacity-20">
                <div className="card text-center flex flex-col overflow-hidden">
                    <div className="my-8">
                    <h2 className="font-bold mb-5 base-golden-center">
                        Obras Executadas
                    </h2>
                    <p className="my-8">
                        {`Adicionar, Editar e deletar Obras executadas`}
                    </p>
                    <Button className="mt-5">Editar</Button>
                    </div>
                </div>
                </Link>
            </div>
            </div>
            <div className="card rounded-md overflow-hidden ring-1 ring-blue-600 ring-opacity-20">
            <div className="p-6 flex flex-col justify-between ">
                <Link to="/" className="rounded-md overflow-hidden ring-1 ring-blue-600 ring-opacity-20">
                <div className="card text-center flex flex-col overflow-hidden">
                    <div className="my-8">
                    <h2 className="font-bold mb-5 base-golden-center">
                        Contato
                    </h2>
                    <p className="my-8">
                        {`Adicionar, editar e deletar Contato`}
                    </p>
                    <Button className="mt-5">Editar</Button>
                    </div>
                </div>
                </Link> 
            </div>
            </div>
        </div>      
    </Layout>
  );
}

export default Edicao;